import axios from "../../axios-base";

const errorBuild = (error) => {
  let resError = "Алдаа гарлаа дахин оролдож үзнэ үү";

  if (resError) {
    resError = error.message;
  }

  if (error.response !== undefined && error.response.status !== undefined) {
    resError = error.response.status;
  }
  if (
    error.response !== undefined &&
    error.response.data !== undefined &&
    error.response.data.error !== undefined
  ) {
    resError = error.response.data.error.message;
  }
  return resError;
};

export const clear = () => {
  return {
    type: "CLEAR_SERVICE_PARTNER",
  };
};

// SAVE SERVICE_PARTNER
export const saveServicePartnerInit = () => {
  return {
    type: "CREATE_SERVICE_PARTNER_INIT",
  };
};

export const saveServicePartner = (data) => {
  return function (dispatch) {
    dispatch(saveServicePartnerStart());
    axios
      .post(`/service-partners`, data)
      .then((response) => {
        const result = response.data;
        dispatch(saveServicePartnerSuccess(result));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(saveServicePartnerError(resError));
      });
  };
};

export const saveServicePartnerStart = () => {
  return {
    type: "CREATE_SERVICE_PARTNER_START",
  };
};

export const saveServicePartnerSuccess = (result) => {
  return {
    type: "CREATE_SERVICE_PARTNER_SUCCESS",
    servicePartner: result,
  };
};

export const saveServicePartnerError = (error) => {
  return {
    type: "CREATE_SERVICE_PARTNER_ERROR",
    error,
  };
};

// Excel servicePartner
export const getExcelData = (query) => {
  return function (dispatch) {
    dispatch(getExcelDataStart());
    axios
      .get("service-partners/excel?" + query)
      .then((response) => {
        const data = response.data.data;
        dispatch(getExcelDataSuccess(data));
      })
      .catch((error) => {
        let resError = errorBuild(error);
        dispatch(getExcelDataError(resError));
      });
  };
};

const getExcelDataStart = () => {
  return {
    type: "GET_SERVICE_PARTNER_EXCELDATA_START",
  };
};

const getExcelDataSuccess = (data) => {
  return {
    type: "GET_SERVICE_PARTNER_EXCELDATA_SUCCESS",
    excel: data,
  };
};

const getExcelDataError = (error) => {
  return {
    type: "GET_SERVICE_PARTNER_EXCELDATA_ERROR",
    error,
  };
};

// LOAD SERVICE_PARTNER

export const loadServicePartner = (query = "") => {
  return function (dispatch) {
    dispatch(loadServicePartnerStart());
    axios
      .get("/service-partners?" + query)
      .then((response) => {
        const loadServicePartner = response.data.data;
        const pagination = response.data.pagination;
        dispatch(loadServicePartnerSuccess(loadServicePartner));
        dispatch(loadPagination(pagination));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(loadServicePartnerError(resError));
      });
  };
};

export const loadServicePartnerStart = () => {
  return {
    type: "LOAD_SERVICE_PARTNERS_START",
  };
};

export const loadServicePartnerSuccess = (servicePartners, pagination) => {
  return {
    type: "LOAD_SERVICE_PARTNERS_SUCCESS",
    servicePartners,
    pagination,
  };
};

export const loadServicePartnerError = (error) => {
  return {
    type: "LOAD_SERVICE_PARTNERS_ERROR",
    error,
  };
};

export const loadPagination = (pagination) => {
  return {
    type: "LOAD_SERVICE_PARTNER_PAGINATION",
    pagination,
  };
};

export const deleteMultServicePartner = (ids) => {
  return function (dispatch) {
    dispatch(deleteMultStart());
    axios
      .delete("service-partners/delete", { params: { id: ids } })
      .then((response) => {
        const deleteServicePartner = response.data.data;
        dispatch(deleteServicePartnerSuccess(deleteServicePartner));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(deleteServicePartnerError(resError));
      });
  };
};

export const deleteMultStart = () => {
  return {
    type: "DELETE_MULT_SERVICE_PARTNER_START",
  };
};

export const deleteServicePartnerSuccess = (deleteData) => {
  return {
    type: "DELETE_MULT_SERVICE_PARTNER_SUCCESS",
    deleteServicePartner: deleteData,
  };
};

export const deleteServicePartnerError = (error) => {
  return {
    type: "DELETE_MULT_SERVICE_PARTNER_ERROR",
    error,
  };
};

// GET SERVICE_PARTNER

export const getInit = () => {
  return {
    type: "GET_SERVICE_PARTNER_INIT",
  };
};

export const getServicePartner = (id) => {
  return function (dispatch) {
    dispatch(getServicePartnerStart());
    axios
      .get("service-partners/" + id)
      .then((response) => {
        const servicePartner = response.data.data;
        dispatch(getServicePartnerSuccess(servicePartner));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(getServicePartnerError(resError));
      });
  };
};

export const getServicePartnerStart = () => {
  return {
    type: "GET_SERVICE_PARTNER_START",
  };
};

export const getServicePartnerSuccess = (servicePartner) => {
  return {
    type: "GET_SERVICE_PARTNER_SUCCESS",
    servicePartner,
  };
};

export const getServicePartnerError = (error) => {
  return {
    type: "GET_SERVICE_PARTNER_ERROR",
    error,
  };
};

//UPDATE SERVICE_PARTNER

export const updateServicePartner = (id, data) => {
  return function (dispatch) {
    dispatch(updateServicePartnerStart());
    axios
      .put(`service-partners/${id}`, data)
      .then((response) => {
        const result = response.data;
        dispatch(updateServicePartnerSuccess(result));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(updateServicePartnerError(resError));
      });
  };
};

export const updateServicePartnerStart = () => {
  return {
    type: "UPDATE_SERVICE_PARTNER_START",
  };
};

export const updateServicePartnerSuccess = (result) => {
  return {
    type: "UPDATE_SERVICE_PARTNER_SUCCESS",
    updateServicePartner: result,
  };
};

export const updateServicePartnerError = (error) => {
  return {
    type: "UPDATE_SERVICE_PARTNER_ERROR",
    error,
  };
};

export const getCountServicePartner = () => {
  return function (dispatch) {
    dispatch(getCountServicePartnerStart());

    axios
      .get(`service-partners/count`)
      .then((response) => {
        const result = response.data.data;
        dispatch(getCountServicePartnerSuccess(result));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(getCountServicePartnerError(resError));
      });
  };
};

export const getCountServicePartnerStart = () => {
  return {
    type: "GET_COUNT_SERVICE_PARTNER_START",
  };
};

export const getCountServicePartnerSuccess = (result) => {
  return {
    type: "GET_COUNT_SERVICE_PARTNER_SUCCESS",
    orderCount: result,
  };
};

export const getCountServicePartnerError = (error) => {
  return {
    type: "GET_COUNT_SERVICE_PARTNER_ERROR",
    error,
  };
};
