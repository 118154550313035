const initialState = {
  loading: false,
  error: null,
  success: null,
  servicePartners: [],
  paginationLast: {},
  excelData: [],
  servicePartner: {},
  //count
  countLoading: false,
  totalCount: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "CLEAR_SERVICE_PARTNER":
      return {
        ...state,
        error: null,
        success: null,
        servicePartners: [],
        servicePartner: {},
        excelData: [],
        loading: false,
      };

    case "LOAD_SERVICE_PARTNERS_START":
      return {
        ...state,
        loading: true,
        error: null,
        suceess: null,
        servicePartners: [],
      };

    case "LOAD_SERVICE_PARTNERS_SUCCESS":
      return {
        ...state,
        loading: false,
        servicePartners: action.servicePartners,
      };

    case "LOAD_SERVICE_PARTNERS_ERROR":
      return {
        ...state,
        loading: false,
        success: null,
        servicePartners: [],
        error: action.error,
      };

    case "LOAD_SERVICE_PARTNER_PAGINATION":
      return {
        ...state,
        paginationLast: action.pagination,
      };

    // EXCEL
    case "GET_SERVICE_PARTNER_EXCELDATA_START":
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
        excelData: [],
      };

    case "GET_SERVICE_PARTNER_EXCELDATA_SUCCESS":
      return {
        ...state,
        loading: false,
        excelData: action.excel,
        error: null,
        success: null,
      };

    case "GET_SERVICE_PARTNER_EXCELDATA_ERROR":
      return {
        ...state,
        loading: false,
        success: null,
        error: action.error,
        excelData: [],
      };

    // SAVE
    case "CREATE_SERVICE_PARTNER_INIT":
      return {
        ...state,
        loading: false,
        error: null,
        success: null,
      };

    case "CREATE_SERVICE_PARTNER_START":
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };

    case "CREATE_SERVICE_PARTNER_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        servicePartner: action.servicePartner,
        success: "Амжилттай нэмэгдлээ",
      };
    case "CREATE_SERVICE_PARTNER_ERROR":
      return {
        ...state,
        loading: false,
        error: action.error,
        success: null,
      };

    case "DELETE_MULT_SERVICE_PARTNER_START":
      return {
        ...state,
        loading: true,
        success: null,
        error: null,
      };
    case "DELETE_MULT_SERVICE_PARTNER_SUCCESS":
      return {
        ...state,
        loading: false,
        success: "Амжилттай устгагдлаа",
        error: null,
      };
    case "DELETE_MULT_SERVICE_PARTNER_ERROR":
      return {
        ...state,
        loading: false,
        success: null,
        error: action.error,
      };

    //GET
    case "GET_SERVICE_PARTNER_INIT":
      return {
        ...state,
        loading: false,
        success: null,
        error: null,
        servicePartner: {},
      };

    case "GET_SERVICE_PARTNER_START":
      return {
        ...state,
        loading: true,
        servicePartner: {},
        error: null,
      };

    case "GET_SERVICE_PARTNER_SUCCESS":
      return {
        ...state,
        loading: false,
        servicePartner: action.servicePartner,
        error: null,
      };

    case "GET_SERVICE_PARTNER_ERROR":
      return {
        ...state,
        loading: false,
        servicePartner: {},
        error: action.error,
      };
    //UPDATE
    case "UPDATE_SERVICE_PARTNER_START":
      return {
        ...state,
        success: null,
        loading: true,
        error: null,
      };
    case "UPDATE_SERVICE_PARTNER_SUCCESS":
      return {
        ...state,
        loading: false,
        success: "Мэдээллийг амжилттай шинэчлэгдлээ",
        error: null,
      };
    case "UPDATE_SERVICE_PARTNER_ERROR":
      return {
        ...state,
        loading: false,
        success: null,
        error: action.error,
      };

    // GET COUNT
    case "GET_COUNT_SERVICE_PARTNER_START":
      return {
        ...state,
        countLoading: true,
        totalCount: null,
        error: null,
      };
    case "GET_COUNT_SERVICE_PARTNER_SUCCESS":
      return {
        ...state,
        coutLoading: false,
        totalCount: action.orderCount,
        error: null,
      };
    case "GET_COUNT_SERVICE_PARTNER_ERROR":
      return {
        ...state,
        countLoading: false,
        totalCount: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
