import axios from "../../axios-base";

const errorBuild = (error) => {
  let resError = "Алдаа гарлаа дахин оролдож үзнэ үү";

  if (resError) {
    resError = error.message;
  }

  if (error.response !== undefined && error.response.status !== undefined) {
    resError = error.response.status;
  }
  if (
    error.response !== undefined &&
    error.response.data !== undefined &&
    error.response.data.error !== undefined
  ) {
    resError = error.response.data.error.message;
  }
  return resError;
};

export const clear = () => {
  return {
    type: "CLEAR_PROJECT",
  };
};

// SAVE PROJECT
export const saveProjectInit = () => {
  return {
    type: "CREATE_PROJECT_INIT",
  };
};

export const saveProject = (project) => {
  return function (dispatch, getState) {
    dispatch(saveProjectStart());
    axios
      .post(`/projects`, project)
      .then((response) => {
        const result = response.data;
        dispatch(saveProjectSuccess(result));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(saveProjectError(resError));
      });
  };
};

export const saveProjectStart = () => {
  return {
    type: "CREATE_PROJECT_START",
  };
};

export const saveProjectSuccess = (result) => {
  return {
    type: "CREATE_PROJECT_SUCCESS",
    project: result,
  };
};

export const saveProjectError = (error) => {
  return {
    type: "CREATE_PROJECT_ERROR",
    error,
  };
};

// Excel project
export const getExcelData = (query) => {
  return function (dispatch) {
    dispatch(getExcelDataStart());
    axios
      .get("project/excel?" + query)
      .then((response) => {
        const data = response.data.data;
        dispatch(getExcelDataSuccess(data));
      })
      .catch((error) => {
        let resError = errorBuild(error);
        dispatch(getExcelDataError(resError));
      });
  };
};

const getExcelDataStart = () => {
  return {
    type: "GET_PROJECT_EXCELDATA_START",
  };
};

const getExcelDataSuccess = (data) => {
  return {
    type: "GET_PROJECT_EXCELDATA_SUCCESS",
    excel: data,
  };
};

const getExcelDataError = (error) => {
  return {
    type: "GET_PROJECT_EXCELDATA_ERROR",
    error,
  };
};

// LOAD PROJECT

export const loadProject = (query = "") => {
  return function (dispatch, getState) {
    dispatch(loadProjectStart());
    axios
      .get("projects?" + query)
      .then((response) => {
        const loadProject = response.data.data;
        const pagination = response.data.pagination;
        dispatch(loadProjectSuccess(loadProject));
        dispatch(loadPagination(pagination));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(loadProjectError(resError));
      });
  };
};

export const loadProjectStart = () => {
  return {
    type: "LOAD_PROJECT_START",
  };
};

export const loadProjectSuccess = (loadProject, pagination) => {
  return {
    type: "LOAD_PROJECT_SUCCESS",
    loadProject,
    pagination,
  };
};

export const loadProjectError = (error) => {
  return {
    type: "LOAD_PROJECT_ERROR",
    error,
  };
};

export const loadPagination = (pagination) => {
  return {
    type: "LOAD_PAGINATION",
    pagination,
  };
};

export const deleteMultProject = (ids) => {
  return function (dispatch, getState) {
    dispatch(deleteMultStart());
    axios
      .delete("projects/delete", { params: { id: ids } })
      .then((response) => {
        const deleteProject = response.data.data;
        dispatch(deleteProjectSuccess(deleteProject));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(deleteProjectError(resError));
      });
  };
};

export const deleteMultStart = () => {
  return {
    type: "DELETE_MULT_PROJECT_START",
  };
};

export const deleteProjectSuccess = (deleteData) => {
  return {
    type: "DELETE_MULT_PROJECT_SUCCESS",
    deleteProject: deleteData,
  };
};

export const deleteProjectError = (error) => {
  return {
    type: "DELETE_MULT_PROJECT_ERROR",
    error,
  };
};

// GET PROJECT

export const getInit = () => {
  return {
    type: "GET_PROJECT_INIT",
  };
};

export const getProject = (id) => {
  return function (dispatch, getState) {
    dispatch(getProjectStart());
    axios
      .get("projects/" + id)
      .then((response) => {
        const project = response.data.data;
        dispatch(getProjectSuccess(project));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(getProjectError(resError));
      });
  };
};

export const getProjectStart = () => {
  return {
    type: "GET_PROJECT_START",
  };
};

export const getProjectSuccess = (project) => {
  return {
    type: "GET_PROJECT_SUCCESS",
    project: project,
  };
};

export const getProjectError = (error) => {
  return {
    type: "GET_PROJECT_ERROR",
    error,
  };
};

//UPDATE PROJECT

export const updateProject = (id, data) => {
  return function (dispatch) {
    dispatch(updateProjectStart());
    axios
      .put(`projects/${id}`, data)
      .then((response) => {
        const result = response.data;
        dispatch(updateProjectSuccess(result));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(updateProjectError(resError));
      });
  };
};

export const updateProjectStart = () => {
  return {
    type: "UPDATE_PROJECT_START",
  };
};

export const updateProjectSuccess = (result) => {
  return {
    type: "UPDATE_PROJECT_SUCCESS",
    updateProject: result,
  };
};

export const updateProjectError = (error) => {
  return {
    type: "UPDATE_PROJECT_ERROR",
    error,
  };
};

export const getCountProject = () => {
  return function (dispatch) {
    dispatch(getCountProjectStart());

    axios
      .get(`projects/count`)
      .then((response) => {
        const result = response.data.data;
        dispatch(getCountProjectSuccess(result));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(getCountProjectError(resError));
      });
  };
};

export const getCountProjectStart = () => {
  return {
    type: "GET_COUNT_PROJECT_START",
  };
};

export const getCountProjectSuccess = (result) => {
  return {
    type: "GET_COUNT_PROJECT_SUCCESS",
    orderCount: result,
  };
};

export const getCountProjectError = (error) => {
  return {
    type: "GET_COUNT_PROJECT_ERROR",
    error,
  };
};
