import axios from "../../axios-base";

const errorMessage = (error) => {
  let resError = "Алдаа гарлаа дахин оролдож үзнэ үү";

  if (resError) {
    resError = error.message;
  }

  if (error.response !== undefined && error.response.status !== undefined) {
    resError = error.response.status;
  }
  if (
    error.response !== undefined &&
    error.response.data !== undefined &&
    error.response.data.error !== undefined
  ) {
    resError = error.response.data.error.message;
  }
  return resError;
};

export const clear = () => {
  return function (dispatch, getState) {
    dispatch(clearStart());
    dispatch(loadProjectCategories());
  };
};

export const clearStart = () => {
  return {
    type: "CLEAR_PROJECTCATEGORIES",
  };
};

export const loadProjectCategories = () => {
  return function (dispatch, getState) {
    dispatch(loadProjectCategoriesStart());
    axios
      .get("project-categories")
      .then((response) => {
        const result = response.data.data;
        dispatch(loadProjectCategoriesSuccess(result));
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(loadProjectCategoriesError(resultError));
      });
  };
};
export const loadProjectCategoriesStart = () => {
  return {
    type: "LOAD_PROJECT_CATEGORIES_START",
  };
};

export const loadProjectCategoriesSuccess = (result) => {
  return {
    type: "LOAD_PROJECT_CATEGORIES_SUCCESS",
    categories: result,
  };
};

export const loadProjectCategoriesError = (error) => {
  return {
    type: "LOAD_PROJECT_CATEGORIES_ERROR",
    error,
  };
};

// SINGLE CATEGORY

export const loadProjectCategory = (projectCategoryId) => {
  return function (dispatch, getState) {
    dispatch(loadProjectCategoryStart());
    axios
      .get(`project-categories/${projectCategoryId}`)
      .then((response) => {
        const loadedProjectCategory = response.data.data;
        dispatch(loadProjectCategorySuccess(loadedProjectCategory));
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(loadProjectCategoryError(resultError));
      });
  };
};

export const loadProjectCategoryStart = () => {
  return {
    type: "LOAD_PROJECT_CATEGORY_START",
  };
};

export const loadProjectCategorySuccess = (result) => {
  return {
    type: "LOAD_PROJECT_CATEGORY_SUCCESS",
    category: result,
  };
};

export const loadProjectCategoryError = (error) => {
  return {
    type: "LOAD_PROJECT_CATEGORY_ERROR",
    error,
  };
};

// Change positions
export const changePosition = (data) => {
  return function (dispatch) {
    dispatch(changePositionStart());

    axios
      .post("project-categories/change", data)
      .then((response) => {
        const result = response.data.data;
        dispatch(changePositionSuccess(result));
        dispatch(loadProjectCategories());
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(changePositionError(resultError));
      });
  };
};

export const changePositionStart = (result) => {
  return {
    type: "PROJECTCATEGORIES_CHANGE_POSITION_START",
  };
};

export const changePositionSuccess = (data) => {
  return {
    type: "PROJECTCATEGORIES_CHANGE_POSITION_SUCCESS",
    menus: data,
  };
};

export const changePositionError = (error) => {
  return {
    type: "PROJECTCATEGORIES_CHANGE_POSITION_ERROR",
    error: error,
  };
};

// DELETE CATEGORY

export const deleteProjectCategory = (categoryId, data) => {
  return function (dispatch, getState) {
    dispatch(deleteProjectCategoryStart());

    axios
      .delete(`project-categories/${categoryId}`, data)
      .then((response) => {
        const resultCategory = response.data.data;
        dispatch(deleteProjectCategorySuccess(resultCategory));
        dispatch(loadProjectCategories());
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(deleteProjectCategoryError(resultError));
      });
  };
};

export const deleteProjectCategoryStart = () => {
  return {
    type: "DELETE_PROJECT_CATEGORY_START",
  };
};

export const deleteProjectCategorySuccess = (result) => {
  return {
    type: "DELETE_PROJECT_CATEGORY_SUCCESS",
    dlProject: result,
  };
};

export const deleteProjectCategoryError = (error) => {
  return {
    type: "DELETE_PROJECT_CATEGORY_ERROR",
    error,
  };
};

// SAVE CATEGORY

export const saveProjectCategory = (category) => {
  return function (dispatch, getState) {
    dispatch(saveProjectCategoryStart());
    let data = {
      name: category.name,
      status: category.status,
    };

    if (category.parentId !== null) {
      data = {
        name: category.name,
        parentId: category.parentId,
      };
    }

    data.language = category.language;
    data.status = category.status;

    axios
      .post(`project-categories`, data)
      .then((response) => {
        const resultCategory = response.data.data;
        dispatch(saveProjectCategorySuccess(resultCategory));
        dispatch(loadProjectCategories());
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(saveProjectCategoryError(resultError));
      });
  };
};

export const saveProjectCategoryStart = () => {
  return {
    type: "CREATE_PROJECT_CATEGORY_START",
  };
};

export const saveProjectCategorySuccess = (resultCategory) => {
  return {
    type: "CREATE_PROJECT_CATEGORY_SUCCESS",
    resultCategory: resultCategory,
  };
};

export const saveProjectCategoryError = (error) => {
  return {
    type: "CREATE_PROJECT_CATEGORY_ERROR",
    error: error,
  };
};

// UPDATE CATEGORY

export const updateProjectCategory = (category, id) => {
  return function (dispatch) {
    dispatch(updateProjectCategoryStart());
    const data = {
      name: category.name,
    };

    axios
      .put(`project-categories/${id}`, data)
      .then((response) => {
        const resultCategory = response.data.data;
        dispatch(updateProjectCategorySuccess(resultCategory));
        dispatch(loadProjectCategories());
        dispatch(loadProjectCategory(id));
      })
      .catch((error) => {
        const resultError = errorMessage(error);
        dispatch(updateProjectCategoryError(resultError));
      });
  };
};

export const updateProjectCategoryStart = () => {
  return {
    type: "UPDATE_PROJECT_CATEGORY_START",
  };
};

export const updateProjectCategorySuccess = (resultCategory) => {
  return {
    type: "UPDATE_PROJECT_CATEGORY_SUCCESS",
    resultCategory: resultCategory,
  };
};

export const updateProjectCategoryError = (error) => {
  return {
    type: "UPDATE_PROJECT_CATEGORY_ERROR",
    error: error,
  };
};
