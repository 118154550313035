import axios from "../../axios-base";

const errorBuild = (error) => {
  let resError = "Алдаа гарлаа дахин оролдож үзнэ үү";

  if (resError) {
    resError = error.message;
  }

  if (error.response !== undefined && error.response.status !== undefined) {
    resError = error.response.status;
  }
  if (
    error.response !== undefined &&
    error.response.data !== undefined &&
    error.response.data.error !== undefined
  ) {
    resError = error.response.data.error.message;
  }
  return resError;
};

export const clear = () => {
  return {
    type: "CLEAR_FACT",
  };
};

// SAVE FACT
export const saveFactsInit = () => {
  return {
    type: "CREATE_FACT_INIT",
  };
};

export const saveFacts = (data) => {
  return function (dispatch, getState) {
    dispatch(saveFactsStart());
    axios
      .post(`/facts`, data)
      .then((response) => {
        const result = response.data;
        dispatch(saveFactsSuccess(result));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(saveFactsError(resError));
      });
  };
};

export const saveFactsStart = () => {
  return {
    type: "CREATE_FACT_START",
  };
};

export const saveFactsSuccess = (result) => {
  return {
    type: "CREATE_FACT_SUCCESS",
    fact: result,
  };
};

export const saveFactsError = (error) => {
  return {
    type: "CREATE_FACT_ERROR",
    error,
  };
};

// Excel fact
export const getExcelData = (query) => {
  return function (dispatch) {
    dispatch(getExcelDataStart());
    axios
      .get("facts/excel?" + query)
      .then((response) => {
        const data = response.data.data;
        dispatch(getExcelDataSuccess(data));
      })
      .catch((error) => {
        let resError = errorBuild(error);
        dispatch(getExcelDataError(resError));
      });
  };
};

const getExcelDataStart = () => {
  return {
    type: "GET_FACT_EXCELDATA_START",
  };
};

const getExcelDataSuccess = (data) => {
  return {
    type: "GET_FACT_EXCELDATA_SUCCESS",
    excel: data,
  };
};

const getExcelDataError = (error) => {
  return {
    type: "GET_FACT_EXCELDATA_ERROR",
    error,
  };
};

// LOAD FACT

export const loadFacts = (query = "") => {
  return function (dispatch, getState) {
    dispatch(loadFactsStart());
    axios
      .get("facts?" + query)
      .then((response) => {
        const loadFacts = response.data.data;
        const pagination = response.data.pagination;
        dispatch(loadFactsSuccess(loadFacts));
        dispatch(loadPagination(pagination));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(loadFactsError(resError));
      });
  };
};

export const loadFactsStart = () => {
  return {
    type: "LOAD_FACT_START",
  };
};

export const loadFactsSuccess = (facts, pagination) => {
  return {
    type: "LOAD_FACT_SUCCESS",
    facts,
    pagination,
  };
};

export const loadFactsError = (error) => {
  return {
    type: "LOAD_FACT_ERROR",
    error,
  };
};

export const loadPagination = (pagination) => {
  return {
    type: "LOAD_PAGINATION",
    pagination,
  };
};

export const deleteMultFacts = (ids) => {
  return function (dispatch, getState) {
    dispatch(deleteMultStart());
    axios
      .delete("facts/delete", { params: { id: ids } })
      .then((response) => {
        const deleteFacts = response.data.data;
        dispatch(deleteFactsSuccess(deleteFacts));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(deleteFactsError(resError));
      });
  };
};

export const deleteMultStart = () => {
  return {
    type: "DELETE_MULT_FACT_START",
  };
};

export const deleteFactsSuccess = (deleteData) => {
  return {
    type: "DELETE_MULT_FACT_SUCCESS",
    deleteFacts: deleteData,
  };
};

export const deleteFactsError = (error) => {
  return {
    type: "DELETE_MULT_FACT_ERROR",
    error,
  };
};

// GET FACT

export const getInit = () => {
  return {
    type: "GET_FACT_INIT",
  };
};

export const getFact = (id) => {
  return function (dispatch, getState) {
    dispatch(getFactStart());
    axios
      .get("facts/" + id)
      .then((response) => {
        const fact = response.data.data;
        dispatch(getFactSuccess(fact));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(getFactError(resError));
      });
  };
};

export const getFactStart = () => {
  return {
    type: "GET_FACT_START",
  };
};

export const getFactSuccess = (fact) => {
  return {
    type: "GET_FACT_SUCCESS",
    fact,
  };
};

export const getFactError = (error) => {
  return {
    type: "GET_FACT_ERROR",
    error,
  };
};

//UPDATE FACT

export const updateFacts = (id, data) => {
  return function (dispatch) {
    dispatch(updateFactsStart());
    axios
      .put(`facts/${id}`, data)
      .then((response) => {
        const result = response.data;
        dispatch(updateFactsSuccess(result));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(updateFactsError(resError));
      });
  };
};

export const updateFactsStart = () => {
  return {
    type: "UPDATE_FACT_START",
  };
};

export const updateFactsSuccess = (result) => {
  return {
    type: "UPDATE_FACT_SUCCESS",
    updateFacts: result,
  };
};

export const updateFactsError = (error) => {
  return {
    type: "UPDATE_FACT_ERROR",
    error,
  };
};

export const getCountFacts = () => {
  return function (dispatch) {
    dispatch(getCountFactsStart());

    axios
      .get(`facts/count`)
      .then((response) => {
        const result = response.data.data;
        dispatch(getCountFactsSuccess(result));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(getCountFactsError(resError));
      });
  };
};

export const getCountFactsStart = () => {
  return {
    type: "GET_COUNT_FACT_START",
  };
};

export const getCountFactsSuccess = (result) => {
  return {
    type: "GET_COUNT_FACT_SUCCESS",
    orderCount: result,
  };
};

export const getCountFactsError = (error) => {
  return {
    type: "GET_COUNT_FACT_ERROR",
    error,
  };
};
