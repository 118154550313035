const base = {
  // siteUrl: "http://localhost:3000/",
  // baseUrl: "http://localhost:3000/",
  // cdnUrl: "http://localhost:1052/uploads/",
  // apiUrl: "http://localhost:1052/v1/api/",
  cdnUrl: "https://nbik.mn/uploads/",
  baseUrl: "https://admin.nbik.mn/",
  siteUrl: "https://nbik.mn/",
  apiUrl: "https://admin.nbik.mn/api/",
};

export default base;
